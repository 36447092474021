import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FormattedTitle from "../components/common/FormattedTitle"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"
import { buildPageDescription } from "../lib/helper"
import { SEO as SearchEngineOptimization } from "../components/common/seo"

const ReleasePage = props => {
  const { pageContext } = props
  const { title, embedCode, body, artist, thumbnail = {} } = pageContext
  const releaseDate = pageContext?.releaseDate?.value || "Unknown"
  const artistName = artist?.entity?.entityLabel || "Unknown"
  const image = getImage(thumbnail)
  return (
    <Layout pageType="release">
      <div
        className="p-4 px-2 px-md-5 position-relative"
        style={{ zIndex: "2" }}
      >
        <h1>
          <FormattedTitle forceUnderline={false}>{title}</FormattedTitle>
        </h1>
        <h3>
          <FormattedTitle forceUnderline={false}>{artistName}</FormattedTitle>
        </h3>
        <span>Released {releaseDate}</span>
        <div className="row py-4 py-md-4 gy-4 gy-md-0">
          <div className="col col-12 col-md-6">
            {image && (
              <div>
                <GatsbyImage
                  alt={title || ""}
                  image={image}
                  imgClassName="preview"
                  loading="eager"
                  className="rounded-4"
                />
              </div>
            )}
            {body?.value && (
              <div
                dangerouslySetInnerHTML={{ __html: body?.value }}
                className="node-body py-4 clearfix"
              />
            )}
          </div>
          <div className="col col-12 col-md-6">
            <div
              className="embed-animation"
              dangerouslySetInnerHTML={{ __html: embedCode }}
            />
          </div>
        </div>
      </div>
      <p className="py-5" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/releases" className="read-more white">
          See all releases >>
        </Link>
      </p>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export default ReleasePage

export const Head = ({ pageContext }) => {
  const { title, body, entityUrl, imageUrl } = pageContext
  const description = buildPageDescription(body?.value)
  return (
    <SearchEngineOptimization
      title={title}
      description={description}
      pathname={entityUrl}
      image={imageUrl}
    />
  )
}
